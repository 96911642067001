
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class ItemSubList extends VueStrong {
  @Prop({ default: [] }) items!: []
  @Prop({ default: null }) itemsTitle: string
  @Prop({ default: null }) isEditable: boolean
  @Prop() accountId: string
  @Prop() businessId: string

  loading = false

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }

  removeItem(item: yakkyo.IFacebookAdAccount) {
    this.$emit('delete-selected-item', { item, businessId: this.businessId, adAccountId: this.accountId })
  }
  emitSelectedItem(item: yakkyo.IFacebookAdAccount) {
    this.$emit('update-selected-item', item)
  }
}
