
import { Component } from 'vue-property-decorator'
import { debounce } from 'lodash'
import VueStrong from '@/VueStrong'
import AccountList from '@/modules/account/components/AccountList.vue'

@Component({
  components: {
    AccountList
  }
})
export default class Business extends VueStrong {
  loading = false
  isEditable = false
  debounceAddFacebookAccount = debounce(this.addFacebookAccount, 500)

  get accounts(): yakkyo.IFacebookAccount[] {
    return this.$store.state.account ? this.$store.state.account.accounts || [] : []
  }

  async addFacebookAccount() {
    this.loading = true
    try {
      const response = await this.$store.state.apiClient.getFacebookAuthURL()
      window.open(response.data, '_blank')
    } catch (error) {
      console.error(error)
    }
    this.loading = false
  }
}
