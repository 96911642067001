
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import ItemSubList from '@/modules/account/components/Bussines/ItemSubList.vue'

@Component({
  components: {
    ItemSubList
  }
})
export default class AccountList extends VueStrong {
  @Prop({ default: [] }) account!: any
  loading = false
  isEditable = false

  selectAdAccount(adAccount: yakkyo.IFacebookAdAccount) {
    this.$store.commit('account/setAdAccount', adAccount)
  }

  async removeFacebookAccount(accountId: string) {
    this.loading = true
    try {
      await this.$store.state.apiClient.disconnectFacebookAccount(accountId)
      await this.$store.dispatch('account/getBusinesses')
    } catch (error) {
      console.error(error)
    }
    this.loading = false
  }

  async refreshFacebookAccount(accountId: string) {
    this.loading = true
    try {
      await this.$store.state.apiClient.fetchFacebookAccount(accountId)
      await this.$store.dispatch('account/getBusinesses')
    } catch (error) {
      console.error(error)
    }
    this.loading = false
  }

  async deleteAdAccount(params: any) {
    this.loading = true
    const payload = {
      ...params,
      type: 'adAccount'
    }
    try {
      await this.$store.dispatch('account/updateAccounts', payload)
    } catch (error) {
      console.error(error)
    }
    this.loading = false
  }
  async deleteFacebookPage(params: any) {
    this.loading = true
    const payload = {
      ...params,
      type: 'facebookPage'
    }
    try {
      await this.$store.dispatch('account/updateAccounts', payload)
    } catch (error) {
      console.error(error)
    }
    this.loading = false
  }
  async deleteFacebookPixel(params: any) {
    this.loading = true
    const payload = {
      ...params,
      type: 'facebookPixel'
    }
    try {
      await this.$store.dispatch('account/updateAccounts', payload)
    } catch (error) {
      console.error(error)
    }
    this.loading = false
  }
  async deleteInstagramPage(params: any) {
    console.log(params)
    this.loading = true
    const payload = {
      ...params,
      type: 'instagramPage'
    }
    try {
      await this.$store.dispatch('account/updateAccounts', payload)
    } catch (error) {
      console.error(error)
    }
    this.loading = false
  }
}
